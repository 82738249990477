<template>
    <div>
        <el-table
            class="table-box"
            ref="multipleTable"
            :data="tableData"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column width="10"> </el-table-column>

            <el-table-column
                label="企业名称"
                min-width="150"
                prop="companyName"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
                label="客户"
                min-width="150"
                prop="customerName"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
                label="员工"
                prop="saleAdminName"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
                label="开通时间"
                show-overflow-tooltip
                min-width="100"
            >
                <template slot-scope="scope">
                    <span>{{
                        scope.row.reportTime
                            ? scope.row.reportTime.slice(0, 10)
                            : ''
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="到期时间"
                show-overflow-tooltip
                min-width="100"
            >
                <template slot-scope="scope">
                    <span>{{
                        scope.row.reportExpireTime
                            ? scope.row.reportExpireTime.slice(0, 10)
                            : ''
                    }}</span>
                </template>
            </el-table-column>

            <el-table-column
                prop="domain"
                label="域名"
                show-overflow-tooltip
                min-width="110"
            >
            </el-table-column>
            <el-table-column
                label="域名所有者"
                prop="domainHave"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
                label="下单后台"
                prop="placeBackstage"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <span>{{
                        scope.row.placeBackstage == 0
                            ? '伽搜boss'
                            : scope.row.placeBackstage == 1
                            ? '钱邮'
                            : scope.row.placeBackstage == 2
                            ? '腾讯云'
                            : scope.row.placeBackstage == 3
                            ? '阿里云'
                            : scope.row.placeBackstage == 4
                            ? '925'
                            : scope.row.placeBackstage == 5
                            ? '华为云'
                            : scope.row.placeBackstage == 6
                            ? '云梦'
                            : scope.row.placeBackstage == 7
                            ? '华中'
                            : scope.row.placeBackstage == 8
                            ? '山总'
                            : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="操作"
                min-width="100"
                show-overflow-tooltip
                fixed="right"
            >
                <template slot-scope="scope">
                    <el-button
                        type="text"
                        style="color: #2370eb"
                        @click="onUpData(scope.row)"
                        >编辑</el-button
                    >
                </template>
            </el-table-column>

            <div slot="empty" class="empty" style="margin-top: 20px">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
        <!-- 编辑 -->
        <el-drawer
            :visible.sync="drawerEdit"
            :direction="direction"
            :before-close="handleClose"
            size="480px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    编辑客户
                </div>
            </template>
            <Edit @close="handleClose" ref="edit" />
        </el-drawer>
    </div>
</template>
<script>
import Edit from '../../../domain/edit.vue';
export default {
    components: {
        Edit,
    },
    data() {
        return {
            tableData: [],
            drawerEdit: false,
            direction: 'rtl',
        };
    },
    methods: {
        getData(data) {
            // console.log(data);

            this.tableData = data;
        },
        // 编辑
        onUpData(row) {
            this.drawerEdit = true;
            setTimeout(() => {
                this.$refs.edit.getRelation(row);
            });
        },
        handleClose() {
            this.drawer = false;
            this.drawerEdit = false;
            this.$emit('againGet');
        },
    },
};
</script>
