<template>
    <div class="basic">
        <div
            v-for="item in contractList"
            style="
    border-bottom: 1px solid #ccc;padding:10px 0"
        >
            <div class="content_list">
                <p>
                    <span class="title">合同编号</span>
                    <span class="cont">{{
                        item.contractNo ? item.contractNo : '- -'
                    }}</span>
                </p>
                <p>
                    <span class="title">甲方</span>
                    <span class="cont">{{
                        item.contractA ? item.contractA : '- -'
                    }}</span>
                </p>
            </div>

            <div class="content_list">
                <p>
                    <span class="title">甲方代表</span>
                    <span class="cont">{{
                        item.representA ? item.representA : '- -'
                    }}</span>
                </p>
                <p>
                    <span class="title">乙方</span>
                    <span class="cont" v-if="item.typeB != 9">
                        {{ $tableDataHandle.typeB(item.typeB) }}
                    </span>
                    <span class="cont" v-if="item.typeB == 9">{{
                        data.contractB
                    }}</span>
                </p>
            </div>
            <div class="content_list">
                <p>
                    <span class="title">合同金额</span>
                    <span class="cont">{{
                        item.contractAmount ? item.contractAmount : '- -'
                    }}</span>
                </p>
            </div>
            <div class="content_list">
                <p>
                    <span class="title">备注</span>
                    <span class="cont">{{
                        item.remark ? item.remark : '- -'
                    }}</span>
                </p>
            </div>
            <div class="content_list">
                <p>
                    <span class="title">营业执照</span>
                    <span class="cont" v-if="item.businessLicenseUrl">
                        <el-image
                            v-if="
                                item.businessLicenseUrl.indexOf('.jpg') != -1 ||
                                    item.businessLicenseUrl.indexOf('.png') !=
                                        -1 ||
                                    item.businessLicenseUrl.indexOf('.gif') !=
                                        -1
                            "
                            style="width: 100px; height: 100px"
                            :src="item.businessLicenseUrl"
                            :preview-src-list="[item.businessLicenseUrl]"
                        >
                        </el-image>
                        <span
                            class="pdf"
                            v-if="
                                item.businessLicenseUrl.indexOf('.pdf') != -1 ||
                                    item.businessLicenseUrl.indexOf('.PDF') !=
                                        -1
                            "
                            @click="onPreview(item.businessLicenseUrl)"
                            style="cursor: pointer;"
                        >
                            <img src="@/img/pdfUrl.png" alt=""
                        /></span>
                        <span
                            class="word"
                            v-if="item.businessLicenseUrl.indexOf('.doc') != -1"
                            @click="onPreview(item.businessLicenseUrl)"
                            style="cursor: pointer;"
                        >
                            <img src="@/img/word.png" alt="" />
                        </span>
                    </span>
                </p>
                <p>
                    <span class="title">附件</span>
                    <span
                        class="cont"
                        v-show="item.contractAttachmentList"
                        v-for="item in item.contractAttachmentList"
                    >
                        <el-image
                            v-if="
                                item.attachmentUrl.indexOf('.jpg') != -1 ||
                                    item.attachmentUrl.indexOf('.png') != -1 ||
                                    item.attachmentUrl.indexOf('.gif') != -1
                            "
                            style="width: 100px; height: 100px"
                            :src="item.attachmentUrl"
                            :preview-src-list="[item.attachmentUrl]"
                        >
                        </el-image>
                        <span
                            class="pdf"
                            v-if="
                                item.attachmentUrl.indexOf('.pdf') != -1 ||
                                    item.attachmentUrl.indexOf('.PDF') != -1
                            "
                            @click="onPreview(item.attachmentUrl)"
                            style="cursor: pointer;"
                        >
                            <img src="@/img/pdfUrl.png" alt="" />
                        </span>
                        <span
                            class="word"
                            v-if="item.attachmentUrl.indexOf('.doc') != -1"
                            @click="onPreview(item.attachmentUrl)"
                            style="cursor: pointer;"
                        >
                            <img src="@/img/word.png" alt="" />
                        </span>
                    </span>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import { customerId } from '@/api/user/ae/customer';
export default {
    data() {
        return {
            contractList: [{}],
            businessLicenseUrl: '',
            attachmentUrl: '',
        };
    },
    methods: {
        getData() {
            let data = {
                param: {
                    customerId: this.$formDetails.state.data.id,
                },
                pageSize: 0,
                pageNum: 0,
            };
            customerId(data).then((res) => {
                // console.log(res);
                this.contractList = res.data.list;
            });
        },
        onPreview(url) {
            if (url.indexOf('.doc') != -1 || url.indexOf('.docx') != -1) {
                window.open(
                    'https://view.officeapps.live.com/op/view.aspx?src=' + url
                );
            } else {
                window.open(url, '_blank');
            }
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.basic {
    width: 100%;
    padding-bottom: 10px;
    background: #fff;
    .content_list {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        padding: 7px 24px;
        font-size: 13px;
        p {
            flex: 1;
        }
        span {
            display: inline-block;
        }
        .title {
            min-width: 65px;
            margin-right: 27px;
            color: #666666;
        }
        .cont {
            color: #333333;
        }
    }
    .content_list:last-child {
        p {
            display: flex;
        }
        span {
            align-items: flex-end;
        }
    }
}
</style>
