<template>
  <div class="clue">
    <div v-for="(form, index) in data">
      <div class="top">
        <span>线索{{ index + 1 }}</span>
        <span>
          <!-- <el-button type="text" size="small" style="color:#2370EB">查看详情</el-button> -->
        </span>
      </div>
      <div class="product">
        <p style="flex:1.5">
          <span class="title">线索产品</span>
          <span>{{ $tableDataHandle.productCategory(form.productClue) }}</span>
        </p>
        <p>
          <span class="title">创建时间</span>
          <span>{{ form.createTime }}</span>
        </p>
      </div>
      <div class="demand">
        <span class="title">需求</span>
        <span>{{ form.remark }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { clueList } from "@/api/user/clue";
export default {
  data() {
    return {
      data: [{}],
    };
  },
  methods: {
    getData() {
      let data = {
        param: {
          customerId: this.$formDetails.state.data.id,
          handleStatus: 0,
        },
      };
      clueList(data).then((res) => {
        this.data = res.data.list;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
  /* WebKit browsers 适配谷歌 */
  font-size: 12px !important;
}
.clue {
  padding: 0 24px;
  font-size: 13px;
  background: #fff;
  .top {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 0;
  }
  .product {
    display: flex;
    p {
      flex: 1;
    }
  }
  .title {
    min-width: 60px;
    display: inline-block;
  }
  .demand {
    padding: 8px 0;
  }
}
</style>
