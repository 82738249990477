<template>
    <div class="contacts">
        <div v-for="(form, index) in data" class="contacts_list">
            <div class="top_index">
                <span>联系人{{ index + 1 }}</span>
                <p v-if="!form.isAdd">
                    <el-button
                        type="text"
                        size="mini"
                        style="color: #333333"
                        @click="onUpData(form)"
                        v-show="!form.disabled && isUpData"
                    >
                        <i
                            class="el-icon-edit-outline"
                            style="color: #2370eb"
                        ></i>
                        编辑</el-button
                    >
                    <el-button
                        v-show="form.disabled"
                        type="text"
                        size="mini"
                        style="
                            color: #2370eb;
                            background: #fff;
                            border-color: #2370eb;
                            padding: 5px 5px;
                        "
                        @click="form.disabled = false"
                    >
                        取消
                    </el-button>
                    <el-button
                        v-show="form.disabled"
                        type="text"
                        size="mini"
                        @click="onSubmit(form)"
                        style="
                            color: #fff;
                            background: #2370eb;
                            border-color: #2370eb;
                            padding: 5px 5px;
                        "
                    >
                        保存
                    </el-button>
                    <el-button
                        v-if="data.length > 1"
                        type="text"
                        size="mini"
                        style="color: #d0021b"
                        @click="onDetele(form)"
                        >删除</el-button
                    >
                </p>
                <p v-if="form.isAdd" style="padding-bottom: 8px">
                    <el-button
                        type="text"
                        size="mini"
                        style="
                            color: #2370eb;
                            background: #fff;
                            border-color: #2370eb;
                            padding: 5px 5px;
                        "
                        @click="onCancel()"
                    >
                        取消
                    </el-button>
                    <el-button
                        type="text"
                        size="mini"
                        @click="onAddSubmit(form)"
                        style="
                            color: #fff;
                            background: #2370eb;
                            border-color: #2370eb;
                            padding: 5px 5px;
                        "
                    >
                        保存
                    </el-button>
                </p>
            </div>
            <el-form
                label-width="90px"
                label-position="left"
                size="small"
                style="padding: 0 23px"
            >
                <el-form-item label="联系人" :required="form.disabled">
                    <el-input
                        :disabled="!form.disabled"
                        v-model="form.contactName"
                        placeholder="请输入"
                    ></el-input>
                </el-form-item>
                <el-form-item label="联系方式">
                    <p
                        v-if="form.customerContactWayDOList"
                        v-for="(item, index) in form.customerContactWayDOList"
                        :key="index"
                        style="margin-bottom: 10px"
                    >
                        <el-select
                            :disabled="!form.disabled"
                            v-model="item.contactWay"
                            size="small"
                            style="width: 30%; padding: 0"
                            clearable
                            placeholder="方式"
                        >
                            <el-option label="电话" :value="1"></el-option>
                            <el-option label="微信" :value="2"></el-option>
                            <el-option label="邮箱" :value="3"></el-option>
                            <el-option label="QQ" :value="4"></el-option>
                            <el-option label="短信" :value="5"></el-option>
                            <el-option label="拜访" :value="6"></el-option>
                            <el-option label="座机" :value="9"></el-option>
                            <el-option label="其他" :value="7"></el-option>
                        </el-select>
                        <el-input
                            :disabled="!form.disabled"
                            style="margin-left: 10px; max-width: 48%"
                            v-model="item.contactContent"
                            placeholder="请输入"
                            size="small"
                        ></el-input>
                        <el-button
                            v-if="index == 0 && form.disabled"
                            @click="addContact(form)"
                            type="primary"
                            icon="el-icon-plus"
                            circle
                            size="mini"
                        ></el-button>
                        <el-button
                            v-if="index > 0 && form.disabled"
                            @click.prevent="removeContact(form, index)"
                            icon="el-icon-minus"
                            type="primary"
                            circle
                            size="mini"
                        ></el-button>
                    </p>
                </el-form-item>
                <el-form-item label="联系人标签">
                    <span
                        v-for="item in form.contactTagList"
                        :class="item.category == 0 ? 'blue' : 'yellow'"
                        class="labelList"
                        >{{ item.tagName }}
                        <span
                            class="cancel"
                            v-show="form.disabled && !form.isAdd"
                            @click="onDeleteLabel(item)"
                        >
                            <span class="el-icon-close"></span>
                        </span>
                    </span>
                    <el-button
                        v-if="!form.isAdd && form.disabled"
                        type="primary"
                        icon="el-icon-plus"
                        size="small"
                        @click="onChoiceLabel(form, form.contactTagList)"
                        style="
                            background: rgba(0, 0, 0, 0.02);
                            border-color: rgba(0, 0, 0, 0.15);
                            color: #000000;
                            padding: 9px;
                        "
                    ></el-button>
                    <el-button
                        v-if="form.isAdd"
                        type="primary"
                        icon="el-icon-plus"
                        size="small"
                        @click="onAddChoiceLabel(form.contactTagList, index)"
                        style="
                            background: rgba(0, 0, 0, 0.02);
                            border-color: rgba(0, 0, 0, 0.15);
                            color: #000000;
                            padding: 9px;
                        "
                    ></el-button>
                </el-form-item>
                <div v-if="form.fold">
                    <el-form-item label="性别">
                        <el-radio-group
                            v-model="form.sex"
                            :disabled="!form.disabled"
                        >
                            <el-radio :label="1">男</el-radio>
                            <el-radio :label="2">女</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="部门">
                        <el-input
                            :disabled="!form.disabled"
                            v-model="form.department"
                            size="small"
                            placeholder="请输入部门"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="职务">
                        <el-input
                            :disabled="!form.disabled"
                            v-model="form.duty"
                            size="small"
                            placeholder="请输入职务"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="公司">
                        <el-input
                            :disabled="!form.disabled"
                            v-model="form.companyName"
                            size="small"
                            placeholder="请输入公司"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="生日">
                        <el-date-picker
                            :disabled="!form.disabled"
                            v-model="form.birthday"
                            type="date"
                            size="small"
                            placeholder="选择日期"
                            value-format="yyyy-MM-dd"
                            style="width: 190px"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="备注">
                        <el-input
                            :disabled="!form.disabled"
                            style="
                                font-family: PingFangSC-Regular, PingFang SC;
                                font-size: 14px;
                            "
                            type="textarea"
                            :rows="2"
                            placeholder="请输入内容"
                            v-model="form.remark"
                        >
                        </el-input>
                    </el-form-item>
                </div>
            </el-form>
            <div class="bottom-but">
                <span class="fold" @click="onFold(form)">
                    <span v-if="!form.fold"
                        >展开
                        <i class="el-icon-arrow-down"></i>
                    </span>
                    <span v-if="form.fold"
                        >收起 <i class="el-icon-arrow-up"></i
                    ></span>
                </span>
            </div>
        </div>
        <!-- 删除联系人 -->
        <el-dialog
            :visible.sync="dialogDelete"
            width="560px"
            :before-close="handleClose"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    删除联系人
                </div>
            </template>
            <div class="Delcontent">是否删除该联系人</div>
            <div class="but-bottom">
                <el-button
                    round
                    type="primary"
                    size="mini"
                    style="
                        background: none;
                        border-color: #d5d6df;
                        color: #666666;
                        margin-left: auto;
                    "
                    @click="dialogDelete = false"
                    >取消</el-button
                >
                <el-button
                    round
                    type="primary"
                    style="
                        background: #2370eb;
                        border-color: #2370eb;
                        color: #fff;
                        margin-right: 30px;
                    "
                    size="mini"
                    @click="onSubmitDel()"
                    >确定</el-button
                >
            </div>
        </el-dialog>
        <el-dialog
            :visible.sync="dialogChoice"
            width="560px"
            :before-close="handleChoice"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    选择标签
                </div>
            </template>
            <ChoiceLabel
                ref="choice"
                @close="handleChoice"
                @addLabel="addLabel"
                @closeAddLabel="closeAddLabel"
            />
        </el-dialog>
        <el-dialog
            :visible.sync="dialogAddlabel"
            width="560px"
            :before-close="handleAddlabel"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    新增个人标签
                </div>
            </template>
            <AddLable @close="handleAddlabel" @addLabel="addLabel" />
        </el-dialog>
    </div>
</template>
<script>
import { contactEdit } from '@/api/user/ae/customer';
import { contactList } from '@/api/user/ae/customer';
import { customerDelete } from '@/api/customer/customer';
import { contactAdd } from '@/api/user/ae/customer';
import { relationList, relationDelete } from '@/api/user/label.js';
import ChoiceLabel from './choiceLabel.vue';
import AddLable from './addLabel.vue';
export default {
    components: {
        ChoiceLabel,
        AddLable,
    },
    data() {
        return {
            data: [{}],
            dialogDelete: false,
            delData: {},
            isUpData: false,
            dialogChoice: false,
            dialogAddlabel: false,
            isGet: false,
            obj: {},
            index: 0,
        };
    },
    methods: {
        onFold(item) {
            // console.log(item);
            item.fold = !item.fold;
        },
        getData(data) {
            this.obj = data;
            if (this.$route.query.type) {
                this.isUpData = true;
            }
            let datas = {
                param: { customerId: data.id },
            };
            contactList(datas).then((res) => {
                // console.log(res);

                this.getLabelData(res.data.list);
            });
        },
        // 获取绑定标签
        getLabelData(list) {
            if (list.length > 0) {
                for (let i = 0; i < list.length; i++) {
                    list[i].fold = false;
                    let data = {
                        param: {
                            bandingId: list[i].id,
                            bandingTableType: '4',
                        },
                    };
                    relationList(data).then((res) => {
                        list[i].contactTagList = res.data.list;
                        list[i].fold = true;
                        list[i].disabled = false;
                        if (
                            !list[i].customerContactWayDOList ||
                            list[i].customerContactWayDOList.length == 0
                        ) {
                            list[i].customerContactWayDOList = [{}];
                        }
                        let that = this;
                        // console.log(list);
                        setTimeout(() => {
                            that.data = list;
                        }, 70);
                    });
                }
            }
        },
        // 新增标签
        addLabel() {
            this.dialogChoice = false;
            this.dialogAddlabel = true;
        },
        // 选择标签
        onChoiceLabel(data, list) {
            this.dialogChoice = true;
            let that = this;
            setTimeout(() => {
                that.$refs.choice.getData(data, 'upData', list);
            }, 200);
        },
        onAddChoiceLabel(item, index) {
            this.index = index;
            this.dialogChoice = true;
            let that = this;
            setTimeout(() => {
                that.$refs.choice.getAddData(item, 'add');
            }, 200);
        },
        // 移除标签
        onDeleteLabel(item) {
            this.$choiceLabel.commit('removeLabel', JSON.stringify(item));
            let data = {
                param: {
                    id: item.id,
                },
            };
            relationDelete(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.onClose();
                }
            });
        },
        closeAddLabel(item) {
            // console.log(item);
            this.data[this.index].contactTagList = item.arr;
            this.dialogChoice = false;
        },
        handleChoice() {
            this.dialogChoice = false;
            this.getData(this.obj);
        },
        handleAddlabel() {
            this.dialogAddlabel = false;
            this.dialogChoice = true;
            this.isGet = !this.isGet;
        },
        // 添加联系人
        onAdd() {
            this.data.unshift({
                fold: true,
                disabled: true,
                isAdd: true,
                customerContactWayDOList: [{}],
            });
        },
        onAddSubmit(form) {
            if (form.contactName == '') {
                return this.$message.error('请输入联系人');
            }
            for (let i in form.customerContactWayDOList) {
                if (
                    !form.customerContactWayDOList[i].contactWay ||
                    !form.customerContactWayDOList[i].contactContent
                ) {
                    return this.$message.error('请输入完整联系方式');
                }
            }
            let data = {
                param: {
                    customerContactDO: form,
                },
            };
            data.param.companyName = this.$formDetails.state.data.companyName;
            data.param.customerContactDO.customerId =
                this.$formDetails.state.data.id;
            contactAdd(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('添加成功');
                }
                this.onClose();
            });
        },
        // 取消添加
        onCancel() {
            this.data.splice(0, 1);
        },
        // 添加联系方式
        addContact(form) {
            for (let i in this.data) {
                if (this.data[i].id == form.id) {
                    // console.log(this.data[i]);
                    this.data[i].customerContactWayDOList.push({});
                    break;
                }
            }
        },
        // 删除联系方式
        removeContact(form, index) {
            for (let i in this.data) {
                if (this.data[i].id == form.id) {
                    // console.log(this.data[i]);
                    this.data[i].customerContactWayDOList.splice(index, 1);
                    break;
                }
            }
        },
        onUpData(form) {
            for (let i in this.data) {
                if (this.data[i].id == form.id) {
                    // console.log(this.data[i]);
                    this.data[i].disabled = true;
                    break;
                }
            }
        },
        onSubmit(form) {
            if (form.contactName == '') {
                return this.$message.error('请输入联系人');
            }
            for (let i in form.customerContactWayDOList) {
                if (
                    !form.customerContactWayDOList[i].contactWay ||
                    !form.customerContactWayDOList[i].contactContent
                ) {
                    return this.$message.error('请输入完整联系方式');
                }
            }
            let data = {
                param: {
                    customerContactDO: form,
                },
            };
            data.param.companyName = this.$formDetails.state.data.companyName;
            contactEdit(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('修改成功');
                }
                this.onClose();
            });
        },
        // 删除
        onDetele(form) {
            this.dialogDelete = true;
            this.delData = form;
        },
        onSubmitDel() {
            let data = {
                param: {
                    id: this.delData.id,
                },
            };
            customerDelete(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('删除成功');
                    this.dialogDelete = false;
                    this.onClose();
                }
            });
        },
        handleClose() {
            this.dialogDelete = false;
        },
        onClose() {
            this.$emit('againGet');
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.contacts {
    width: 100%;
    .contacts_list {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 8px;
        float: left;
        .top_index {
            padding: 12px 8px 0 24px;
            width: auto;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            font-size: 13px;
            color: #333333;
        }
        .bottom-but {
            text-align: center;
            width: auto;
            font-size: 14px;
            color: #2370eb;
            cursor: pointer;
            padding-bottom: 10px;
        }
    }
    .contacts_list:nth-child(odd) {
        margin-right: 1%;
    }
}
.el-button--mini.is-circle {
    padding: 2px;
    background: #fff;
    color: #2370eb;
    font-weight: 600;
    border: 2px solid #2370eb;
    margin-left: 10px;
}
.contact_way_box {
    background-color: #fff;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding-left: 0;
    margin-bottom: 10px;
    position: relative;
}
.Delcontent {
    padding: 100px 80px;
    font-size: 16px;
}
.but-bottom {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    background: #fff;
    justify-content: space-between;
    button {
        width: 72px;
    }
}
.labelList {
    display: inline-block;
    padding: 0 8px;
    height: 30px;
    border: 1px solid #e5e5e5;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    line-height: 30px;
    margin-right: 10px;
    cursor: pointer;
    margin-bottom: 20px;
    position: relative;
    .cancel {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-top: 10px solid rgba(255, 255, 255, 0);
        border-right: 10px solid rgba(255, 255, 255, 0);
        border-bottom: 10px solid rgba(255, 255, 255, 0);
        border-left: 10px solid yellow;
        -webkit-transform: rotate(45deg); /* for Chrome || Safari */
        -moz-transform: rotate(45deg); /* for Firefox */
        -ms-transform: rotate(45deg); /* for IE */
        -o-transform: rotate(45deg);
        right: -10px;
        bottom: -10px;
        .el-icon-close {
            color: #fff;
            position: absolute;
            font-size: 8px;
            left: -12px;
            top: -6px;
            -webkit-transform: rotate(45deg); /* for Chrome || Safari */
            -moz-transform: rotate(45deg); /* for Firefox */
            -ms-transform: rotate(45deg); /* for IE */
            -o-transform: rotate(45deg);
        }
    }
}
.blue {
    color: #2370eb;
    border-color: #2370eb;
    .cancel {
        border-left: 10px solid #2370eb;
    }
}
.yellow {
    color: #fa8c15;
    border-color: #fa8c15;
    .cancel {
        border-left: 10px solid #fa8c15;
    }
}
</style>
<style>
.contacts_list .el-input--suffix .el-input__inner {
    padding-right: 5px !important;
}
</style>
