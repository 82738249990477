<template>
    <div class="trade">
        <div class="switch">
            <p>交易数据</p>
            <div class="tab">
                <span
                    :class="index == 1 ? 'isColor' : ''"
                    @click="onSwitch(1, 'basic_box')"
                    >已成交产品</span
                >
                <span
                    :class="index == 2 ? 'isColor' : ''"
                    @click="onSwitch(2, 'mail_box')"
                    >关联线索</span
                >
                <span
                    :class="index == 3 ? 'isColor' : ''"
                    @click="onSwitch(3, 'website_box')"
                    >关联合同</span
                >
            </div>
        </div>
        <div class="conten_box">
            <div class="basic_box">
                <div class="title">
                    <span style="margin-left:6px">
                        <i
                            class="iconfont icon-chanpin"
                            style="color:#2370EB"
                        ></i>
                        已成交产品</span
                    >
                    <p>
                        <el-button
                            v-show="productFold"
                            @click="productFold = !productFold"
                            type="text"
                            style="margin-right:12px;color:#2162CB"
                        >
                            收起 <i class="el-icon-arrow-up"></i>
                        </el-button>
                        <el-button
                            v-show="!productFold"
                            @click="productFold = !productFold"
                            type="text"
                            style="margin-right:12px;color:#2162CB"
                        >
                            展开 <i class="el-icon-arrow-down"></i>
                        </el-button>
                    </p>
                </div>
                <div>
                    <el-collapse-transition>
                        <div v-show="productFold">
                            <div v-show="isTm">
                                <div class="tm">
                                    <div class="title">
                                        <span
                                            style="margin-left:6px"
                                            class="identification"
                                        >
                                            腾讯企业邮箱</span
                                        >
                                        <p>
                                            <el-button
                                                v-show="tmFold"
                                                @click="tmFold = !tmFold"
                                                type="text"
                                                style="margin-right:12px;color:#2162CB"
                                            >
                                                收起
                                                <i class="el-icon-arrow-up"></i>
                                            </el-button>
                                            <el-button
                                                v-show="!tmFold"
                                                @click="tmFold = !tmFold"
                                                type="text"
                                                style="margin-right:12px;color:#2162CB"
                                            >
                                                展开
                                                <i
                                                    class="el-icon-arrow-down"
                                                ></i>
                                            </el-button>
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <el-collapse-transition>
                                        <div v-show="tmFold">
                                            <TmMail
                                                class="tmMail"
                                                ref="tmMail"
                                                @againGet="getData(row)"
                                            />
                                        </div>
                                    </el-collapse-transition>
                                </div>
                            </div>
                            <div v-show="isAli">
                                <div class="ali">
                                    <div class="title">
                                        <span
                                            style="margin-left:6px"
                                            class="identification"
                                        >
                                            阿里企业邮箱</span
                                        >
                                        <p>
                                            <el-button
                                                v-show="aliFold"
                                                @click="aliFold = !aliFold"
                                                type="text"
                                                style="margin-right:12px;color:#2162CB"
                                            >
                                                收起
                                                <i class="el-icon-arrow-up"></i>
                                            </el-button>
                                            <el-button
                                                v-show="!aliFold"
                                                @click="aliFold = !aliFold"
                                                type="text"
                                                style="margin-right:12px;color:#2162CB"
                                            >
                                                展开
                                                <i
                                                    class="el-icon-arrow-down"
                                                ></i>
                                            </el-button>
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <el-collapse-transition>
                                        <div v-show="aliFold">
                                            <AliMail
                                                class="tmMail"
                                                ref="aliMail"
                                                @againGet="getData(row)"
                                            />
                                        </div>
                                    </el-collapse-transition>
                                </div>
                            </div>
                            <div class="domain" v-show="isDomain">
                                <div class="title">
                                    <span
                                        style="margin-left:6px"
                                        class="identification"
                                    >
                                        域 名</span
                                    >
                                    <p>
                                        <el-button
                                            v-show="domainFold"
                                            @click="domainFold = !domainFold"
                                            type="text"
                                            style="margin-right:12px;color:#2162CB"
                                        >
                                            收起
                                            <i class="el-icon-arrow-up"></i>
                                        </el-button>
                                        <el-button
                                            v-show="!domainFold"
                                            @click="domainFold = !domainFold"
                                            type="text"
                                            style="margin-right:12px;color:#2162CB"
                                        >
                                            展开
                                            <i class="el-icon-arrow-down"></i>
                                        </el-button>
                                    </p>
                                </div>
                                <div>
                                    <el-collapse-transition>
                                        <div v-show="domainFold">
                                            <Domain
                                                ref="domain"
                                                @againGet="getData(row)"
                                            />
                                        </div>
                                    </el-collapse-transition>
                                </div>
                            </div>
                        </div>
                    </el-collapse-transition>
                </div>
            </div>
            <div class="relation">
                <div class="clue">
                    <div class="title">
                        <span style="margin-left:6px">
                            <i
                                class="iconfont icon-chanpin"
                                style="color:#2370EB"
                            ></i>
                            关联线索</span
                        >
                        <p>
                            <el-button
                                v-show="clueFold"
                                @click="clueFold = !clueFold"
                                type="text"
                                style="margin-right:12px;color:#2162CB"
                            >
                                收起 <i class="el-icon-arrow-up"></i>
                            </el-button>
                            <el-button
                                v-show="!clueFold"
                                @click="clueFold = !clueFold"
                                type="text"
                                style="margin-right:12px;color:#2162CB"
                            >
                                展开 <i class="el-icon-arrow-down"></i>
                            </el-button>
                        </p>
                    </div>
                    <div>
                        <el-collapse-transition>
                            <div v-show="clueFold">
                                <Clue ref="clue" />
                            </div>
                        </el-collapse-transition>
                    </div>
                </div>
                <div class="contract">
                    <div class="title">
                        <span style="margin-left:6px">
                            <i
                                class="iconfont icon-chanpin"
                                style="color:#2370EB"
                            ></i>
                            关联合同</span
                        >
                        <p>
                            <el-button
                                v-show="contractFold"
                                @click="contractFold = !contractFold"
                                type="text"
                                style="margin-right:12px;color:#2162CB"
                            >
                                收起 <i class="el-icon-arrow-up"></i>
                            </el-button>
                            <el-button
                                v-show="!contractFold"
                                @click="contractFold = !contractFold"
                                type="text"
                                style="margin-right:12px;color:#2162CB"
                            >
                                展开 <i class="el-icon-arrow-down"></i>
                            </el-button>
                        </p>
                    </div>
                    <div>
                        <el-collapse-transition>
                            <div v-show="contractFold">
                                <Contract ref="contract" />
                            </div>
                        </el-collapse-transition>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TmMail from './product/tm.vue';
import Domain from './product/domain.vue';
import AliMail from './product/ali.vue';
import Clue from './relation/clue/clue.vue';
import Contract from './relation/contract/contract.vue';
import { closedCusProduct } from '@/api/closedCus/closedCus';
import { closedCusList } from '@/api/closedCus/closedCus';
export default {
    components: {
        TmMail,
        Domain,
        Clue,
        Contract,
        AliMail,
    },
    data() {
        return {
            index: 1,
            productFold: true,
            clueFold: true,
            contractFold: true,
            tmFold: true,
            aliFold: true,
            domainFold: true,
            isTm: false,
            isAli: false,
            isDomain: false,
            row: {},
        };
    },

    methods: {
        onSwitch(i) {
            this.index = i;
        },
        getData(row) {
            this.row = row;
            this.isTm = false;
            this.isAli = false;
            this.isDomain = false;
            if (row.customerId == 0) {
                if (row.type == 1) {
                    this.isTm = true;
                    setTimeout(() => {
                        this.$refs.tmMail.getData([row]);
                    });
                }
                if (row.type == 2) {
                    // console.log(row);
                    this.isAli = true;
                    setTimeout(() => {
                        this.$refs.aliMail.getData([row]);
                    });
                }
                if (row.type == 3) {
                    this.isDomain = true;
                    this.$refs.domain.getData([row]);
                }
            } else {
                let obj = {
                    param: {
                        customerId: this.$formDetails.state.data.id,
                    },
                };

                closedCusProduct(obj).then((res) => {
                    for (let i in res.data.list) {
                        if (res.data.list[i].type == 1) {
                            this.isTm = true;
                            let data = {
                                param: {
                                    customerId: this.$formDetails.state.data.id,
                                    type: 1,
                                },
                            };
                            closedCusList(data).then((res) => {
                                // console.log(res);
                                this.$refs.tmMail.getData(res.data.list);
                            });
                        }
                        if (res.data.list[i].type == 2) {
                            this.isAli = true;
                            let data = {
                                param: {
                                    customerId: this.$formDetails.state.data.id,
                                    type: 2,
                                },
                            };
                            closedCusList(data).then((res) => {
                                // console.log(res);
                                this.$refs.aliMail.getData(res.data.list);
                            });
                        }
                        if (res.data.list[i].type == 3) {
                            this.isDomain = true;
                            let data = {
                                param: {
                                    customerId: this.$formDetails.state.data.id,
                                    type: 3,
                                },
                            };
                            closedCusList(data).then((res) => {
                                // console.log(res);
                                this.$refs.domain.getData(res.data.list);
                            });
                        }
                    }
                    this.$refs.clue.getData();
                    this.$refs.contract.getData();
                });
            }
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.trade {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 0;
    padding-bottom: 20px;
    .switch {
        background: #fff;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 13px;
        color: #333333;
        padding: 12px 13px 12px 24px;
        .tab {
            span {
                width: 72px;
                height: 20px;
                border-radius: 2px;
                border: 1px solid #e5e5e5;
                font-size: 12px;
                line-height: 20px;
                margin-left: 8px;
                text-align: center;
                display: inline-block;
                cursor: pointer;
            }
            .isColor {
                border: 1px solid #2370eb;
                color: #2370eb;
            }
        }
    }
    .title {
        height: 49px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #eeeeee;
        font-size: 13px;
        background: #fff;
    }
    .conten_box {
        flex: 1;
        overflow: auto;
        margin-top: 8px;
        .tm {
            .identification {
                display: inline-block;
                background: #2370eb;
                color: #fff;
                border-radius: 2px;
                padding: 3px 0;
                font-size: 13px;
                width: 90px;
                text-align: center;
            }
        }
        .ali {
            .identification {
                display: inline-block;
                background: #f06f1e;
                color: #fff;
                border-radius: 2px;
                padding: 3px 0;
                font-size: 13px;
                width: 90px;
                text-align: center;
            }
        }
        .domain {
            .identification {
                display: inline-block;
                background: #f5a623;
                color: #fff;
                border-radius: 2px;
                width: 90px;
                padding: 3px 0;
                text-align: center;
                font-size: 13px;
            }
        }
    }
    .relation {
        display: flex;
        margin-top: 8px;
        .clue {
            flex: 1;
        }
        .contract {
            flex: 1;
            margin-left: 12px;
        }
    }
}
</style>
