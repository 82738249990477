<template>
    <div>
        <el-form
            ref="form"
            style="
                flex: 1;
                margin-top: 20px;
                margin-left: 40px;
                margin-bottom: 80px;
            "
            label-position="left"
            label-width="100px"
        >
            <el-form-item label="企业名称">
                {{ form.companyName }}</el-form-item
            >
            <el-form-item label="客户" required>
                <el-select
                    remote
                    :remote-method="remoteMethod"
                    v-model="customerId"
                    size="small"
                    filterable
                    style="width: 270px"
                    clearable
                    placeholder="请选择或输入关键字搜索"
                >
                    <el-option
                        v-for="item in Relation"
                        :key="item.id"
                        :label="item.companyName"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <div class="but">
            <el-button
                round
                type="primary"
                size="mini"
                style="
                    background: none;
                    border-color: #d5d6df;
                    color: #666666;
                    margin-left: auto;
                "
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                style="
                    background: #2370eb;
                    border-color: #2370eb;
                    color: #fff;
                    margin-right: 30px;
                "
                size="mini"
                @click="onSubmit"
                >确定</el-button
            >
        </div>
    </div>
</template>
<script>
import { meCustomerList } from '@/api/user/ae/customer';
import { closedCusEdit } from '@/api/closedCus/closedCus';
export default {
    data() {
        return {
            customerId: '',
            Relation: [],
            form: {},
        };
    },
    methods: {
        remoteMethod(query) {
            this.getCustomerData(this.form, query);
        },
        getRelation(obj, name) {
            this.form = obj;
            let data = {
                param: {
                    customerStatusList: [1, 2, 3],
                },
                pageNum: 1,
                pageSize: 20,
            };
            if (name) {
                data.param.name = name;
            }
            data.param.followAdminId = sessionStorage.getItem('adminId');

            meCustomerList(data).then((res) => {
                this.Relation = res.data.list;
            });
        },
        onClose() {
            this.$emit('close');
        },
        onSubmit() {
            if (!this.customerId) {
                return this.$message.error('请选择客户');
            }
            let data = {
                param: {
                    id: this.form.id,
                    customerId: this.customerId,
                },
            };
            closedCusEdit(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.$message.success('关联成功');
                    this.onClose();
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.but {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    background: #fff;
    justify-content: space-between;
    button {
        width: 72px;
    }
}
</style>
