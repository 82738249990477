<template>
  <div class="information">
    <div class="switch">
      <p>基础信息</p>
      <div class="tab">
        <span
          :class="index == 1 ? 'isColor' : ''"
          @click="onSwitch(1, 'basic_box')"
          >基本资料</span
        >
        <span
          :class="index == 2 ? 'isColor' : ''"
          @click="onSwitch(2, 'mail_box')"
          >邮箱需求</span
        >
        <span
          :class="index == 3 ? 'isColor' : ''"
          @click="onSwitch(3, 'website_box')"
          >网站资料</span
        >
        <span
          :class="index == 4 ? 'isColor' : ''"
          @click="onSwitch(4, 'contacts_box')"
          >联系人</span
        >
      </div>
    </div>
    <div class="content_box">
      <div class="left_box">
        <div class="basic_box">
          <div class="title">
            <span style="margin-left:6px">
              <i
                class="iconfont icon-ziliaoshouce-xianxing"
                style="color:#2370EB"
              ></i>
              基本资料</span
            >
            <p>
              <el-button
                @click="onRefresh()"
                type="text"
                style="margin-right:12px;color:#2162CB"
              >
                <i class="el-icon-refresh-right"></i>
                刷新
              </el-button>
              <el-button
                v-show="basicFold"
                @click="basicFold = !basicFold"
                type="text"
                style="margin-right:12px;color:#2162CB"
              >
                收起 <i class="el-icon-arrow-up"></i>
              </el-button>
              <el-button
                v-show="!basicFold"
                @click="basicFold = !basicFold"
                type="text"
                style="margin-right:12px;color:#2162CB"
              >
                展开 <i class="el-icon-arrow-down"></i>
              </el-button>
            </p>
          </div>
          <div>
            <el-collapse-transition>
              <Basic v-show="basicFold" ref="basic" @againGet="againGet" />
            </el-collapse-transition>
          </div>
        </div>
        <div class="website_box">
          <div class="title">
            <span style="margin-left:6px">
              <i
                class="iconfont icon-wangzhan"
                style="color:#2370EB;;font-size:12px"
              ></i>
              网站资料</span
            >
            <p>
              <el-button
                v-show="websiteFold"
                @click="websiteFold = !websiteFold"
                type="text"
                style="margin-right:12px;color:#2162CB"
              >
                收起 <i class="el-icon-arrow-up"></i>
              </el-button>
              <el-button
                v-show="!websiteFold"
                @click="websiteFold = !websiteFold"
                type="text"
                style="margin-right:12px;color:#2162CB"
              >
                展开 <i class="el-icon-arrow-down"></i>
              </el-button>
            </p>
          </div>
          <div>
            <el-collapse-transition>
              <Website
                v-show="websiteFold"
                ref="website"
                @againGet="againGet"
              />
            </el-collapse-transition>
          </div>
        </div>
      </div>
      <div class="right_box">
        <div class="mail_box">
          <div class="title">
            <span style="margin-left:6px">
              <i
                class="iconfont icon-youxiang2"
                style="color:#2370EB;font-size:13px"
              ></i>
              邮箱需求</span
            >
            <p>
              <el-button
                type="text"
                size="small"
                style="color: #333333;"
                @click="onUpData()"
                v-show="!disabledMail && isUpData"
              >
                <i class="el-icon-edit-outline" style="color: #2370EB;"></i>
                编辑</el-button
              >
              <el-button
                v-show="disabledMail"
                type="text"
                size="small"
                style="color: #2370EB;background:#fff;border-color:#2370EB;padding:5px 5px"
                @click="onCancel()"
              >
                取消
              </el-button>
              <el-button
                v-show="disabledMail"
                type="text"
                size="small"
                @click="onSubmit()"
                style="color: #fff;background:#2370EB;border-color:#2370EB;padding:5px 5px"
              >
                保存
              </el-button>
              <el-button
                v-show="MailFold"
                @click="MailFold = !MailFold"
                type="text"
                style="margin-right:12px;color:#2162CB"
              >
                收起 <i class="el-icon-arrow-up"></i>
              </el-button>
              <el-button
                v-show="!MailFold"
                @click="MailFold = !MailFold"
                type="text"
                style="margin-right:12px;color:#2162CB"
              >
                展开 <i class="el-icon-arrow-down"></i>
              </el-button>
            </p>
          </div>
          <div>
            <el-collapse-transition>
              <Mail v-show="MailFold" ref="mail" @againGet="againGet" />
            </el-collapse-transition>
          </div>
        </div>
        <div class="contacts_box">
          <div class="title">
            <span style="margin-left:6px">
              <i
                class="iconfont icon-tongxunlu"
                style="color:#2370EB;font-size:14px"
              ></i>
              联系人</span
            >
            <p>
              <el-button
                v-show="isUpData"
                @click="onAddContacts()"
                size="mini"
                type="primary"
                style="margin-right:12px;color:#2162CB;background:#fff;border-color:#2162CB;"
              >
                新增联系人
              </el-button>
              <el-button
                v-show="contactsFold"
                @click="contactsFold = !contactsFold"
                type="text"
                style="margin-right:12px;color:#2162CB"
              >
                收起 <i class="el-icon-arrow-up"></i>
              </el-button>
              <el-button
                v-show="!contactsFold"
                @click="contactsFold = !contactsFold"
                type="text"
                style="margin-right:12px;color:#2162CB"
              >
                展开 <i class="el-icon-arrow-down"></i>
              </el-button>
            </p>
          </div>
          <div>
            <el-collapse-transition>
              <Contacts
                v-show="contactsFold"
                ref="contacts"
                @againGet="againGet"
              />
            </el-collapse-transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Basic from "./basic/basic.vue";
import Website from "./website/website.vue";
import Mail from "./mail/mail.vue";
import Contacts from "./contacts/contacts.vue";
export default {
  components: {
    Basic,
    Website,
    Mail,
    Contacts,
  },
  data() {
    return {
      data: {},
      index: 1,
      basicFold: true,
      websiteFold: true,
      MailFold: true,
      contactsFold: true,
      disabledMail: false,
      isUpData: false,
    };
  },
  methods: {
    onSwitch(i) {
      this.index = i;
    },
    getData(data) {
      if (this.$route.query.type) {
        this.isUpData = true;
      }
      this.data = data;
      this.disabledMail = false;
      setTimeout(() => {
        this.$refs.basic.getData(data);
        this.$refs.mail.getData(data);
        this.$refs.website.getData(data);
        this.$refs.contacts.getData(data);
      });
    },
    onRefresh() {
      this.$refs.basic.getData(this.data);
    },
    // 新增联系人
    onAddContacts() {
      this.$refs.contacts.onAdd();
    },
    // 邮箱编辑
    onUpData() {
      this.disabledMail = true;
      this.$refs.mail.upData();
    },
    onCancel() {
      this.disabledMail = false;
      this.$refs.mail.cancel();
    },
    onSubmit() {
      this.$refs.mail.onSubmit();
    },
    // 新增网站
    onAddWebsite() {
      this.$refs.website.onAdd();
    },
    againGet() {
      this.$emit("againGet");
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
  /* WebKit browsers 适配谷歌 */
  font-size: 12px !important;
}
.information {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0px;
  .switch {
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 13px;
    color: #333333;
    padding: 12px 13px 12px 24px;
    .tab {
      span {
        width: 72px;
        height: 20px;
        border-radius: 2px;
        border: 1px solid #e5e5e5;
        font-size: 12px;
        line-height: 20px;
        margin-left: 8px;
        text-align: center;
        display: inline-block;
        cursor: pointer;
      }
      .isColor {
        border: 1px solid #2370eb;
        color: #2370eb;
      }
    }
  }
  .content_box {
    flex: 1;
    overflow: auto;
    display: flex;
    .title {
      height: 49px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #eeeeee;
      font-size: 13px;
      background: #fff;
    }
    .left_box {
      flex: 1.5;
      margin-top: 8px;

      .basic_box {
        border-radius: 4px 4px 0 0;
        background: #fff;
      }
      .website_box {
        margin-top: 8px;
        border-radius: 4px 4px 0 0;
        background: #fff;
      }
    }
    .right_box {
      margin-left: 12px;
      flex: 1;
      margin-top: 8px;
    }
    .mail_box {
      background: #fff;
      border-radius: 4px 4px 0 0;
    }
    .contacts_box {
      margin-top: 8px;
      background: #fff;
      border-radius: 4px 4px 0 0;
    }
  }
}
</style>
