<template>
    <div class="label-box">
        <div class="admin-label">
            <p class="title">系统标签</p>
            <div class="label-list">
                <span
                    v-for="(item, index) in adminData"
                    :class="item.ischeck == true ? 'active' : ''"
                    :key="index"
                    @click="btn(item)"
                    >{{ item.tagName }}</span
                >
            </div>
        </div>
        <div class="personal-label">
            <p class="title">个人标签</p>
            <div class="label-list">
                <span
                    v-for="item in personalData"
                    :class="item.ischeck == true ? 'personal' : ''"
                    :key="item.index"
                    @click="btn(item)"
                    >{{ item.tagName }}</span
                >
                <el-button
                    type="primary"
                    icon="el-icon-plus"
                    size="small"
                    @click="onAddLabel"
                    style="
                        background: rgba(0, 0, 0, 0.02);
                        border-color: rgba(0, 0, 0, 0.15);
                        color: #000000;
                        padding: 9px;
                    "
                ></el-button>
            </div>
        </div>
        <div class="but">
            <el-button
                round
                type="primary"
                size="mini"
                style="background: none; border-color: #d5d6df; color: #666666; margin-left: auto"
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                style="background: #2370eb; border-color: #2370eb; color: #fff; margin-right: 30px"
                size="mini"
                @click="onSubmit"
                >确定</el-button
            >
        </div>
    </div>
</template>
<script>
import { labelList, relationLabel, relationDelete } from '@/api/user/label';
export default {
    props: ['isGet', 'labelType', 'index'],
    data() {
        return {
            adminData: [],
            personalData: [],
            isChange: -1,
            type: '',
            i: -1,
            data: {},
            isType: '',
            labelList: [],
        };
    },
    methods: {
        getData(data, isType, tagList) {
            this.data = data;
            this.isType = isType;
            this.labelList = tagList;
            // console.log(tagList);
            this.getLabel();
        },
        getAddData(list, type) {
            this.isType = type;
            this.labelList = list;
            this.getLabel();
        },
        btn(item, index) {
            // console.log(item);
            if (item.ischeck == false) {
                item.ischeck = true;
                if (this.isType == 'upData') {
                    let data = {
                        param: {
                            tagId: item.id,
                            bandingId: this.data.id,
                            bandingTableType: 3,
                            adminId: sessionStorage.getItem('adminId'),
                        },
                    };
                    relationLabel(data).then((res) => {
                        if (res.code == 200) {
                            this.$message.success('操作成功');
                        }
                        item.relationID = res.data.id;
                    });
                }
            } else {
                item.ischeck = false;
                if (this.isType == 'upData') {
                    let data = {
                        param: {
                            id: item.relationID,
                        },
                    };
                    relationDelete(data).then((res) => {
                        if (res.code == 200) {
                            this.$message.success('操作成功');
                        }
                    });
                }
            }
        },
        getLabel() {
            let data = {
                param: {
                    category: 0,
                    type: 3,
                },
                pageNum: 0,
                pageSize: 0,
            };

            labelList(data).then((res) => {
                for (let i in res.data.list) {
                    res.data.list[i].ischeck = false;
                }
                if (this.isType == 'upData') {
                    for (let i = 0; i < res.data.list.length; i++) {
                        for (let j = 0; j < this.labelList.length; j++) {
                            if (this.labelList[j].tagId) {
                                if (res.data.list[i].id == this.labelList[j].id) {
                                    res.data.list[i].ischeck = true;
                                    res.data.list[i].relationID = this.labelList[j].id;
                                }
                            } else {
                                if (res.data.list[i].id == this.labelList[j].id) {
                                    res.data.list[i].ischeck = true;
                                    res.data.list[i].relationID = this.labelList[j].id;
                                }
                            }
                        }
                    }
                }
                this.adminData = res.data.list;
            });
            let datas = {
                param: {
                    category: 1,
                    type: 3,
                },
                pageNum: 0,
                pageSize: 0,
            };
            labelList(datas).then((res) => {
                for (let i in res.data.list) {
                    res.data.list[i].ischeck = '';
                }
                if (this.isType == 'upData') {
                    for (let i = 0; i < res.data.list.length; i++) {
                        for (let j = 0; j < this.labelList.length; j++) {
                            if (this.labelList[j].tagId) {
                                if (res.data.list[i].id == this.labelList[j].tagId) {
                                    res.data.list[i].ischeck = true;
                                    res.data.list[i].relationID = this.labelList[j].id;
                                }
                            } else {
                                if (res.data.list[i].id == this.labelList[j].id) {
                                    res.data.list[i].ischeck = true;
                                    res.data.list[i].relationID = this.labelList[j].id;
                                }
                            }
                        }
                    }
                }

                this.personalData = res.data.list;
            });
        },
        // 添加标签
        onAddLabel() {
            this.$emit('addLabel');
        },
        // 提交
        onSubmit() {
            let arr = [];
            for (let i in this.adminData) {
                if (this.adminData[i].ischeck) {
                    arr.push(this.adminData[i]);
                }
            }
            for (let i in this.personalData) {
                if (this.personalData[i].ischeck) {
                    arr.push(this.personalData[i]);
                }
            }

            let data = {
                arr: arr,
            };

            if (this.isType == 'add') {
                this.$emit('closeAddLabel', data);
            } else if (this.isType == 'upData') {
                this.onClose();
            }
        },
        // 取消
        onClose() {
            this.$emit('close');
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.label-box {
    width: 100%;
    background: #f9f9f9;
    .admin-label {
        background: #fff;
        padding: 20px 36px 60px 36px;
        margin-bottom: 10px;
        .title {
            font-size: 14px;
            font-weight: 500;
            color: #333333;
            line-height: 20px;
            padding-bottom: 20px;
        }
    }
    .personal-label {
        background: #fff;
        padding: 20px 36px 40px 36px;
        .title {
            font-size: 14px;
            font-weight: 500;
            color: #333333;
            line-height: 20px;
            padding-bottom: 20px;
        }
    }
    .label-list span {
        display: inline-block;
        padding: 0 10px;
        height: 30px;
        border: 1px solid #e5e5e5;
        border-radius: 2px;
        color: #666666;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        text-align: center;
        line-height: 30px;
        margin-right: 32px;
        cursor: pointer;
        margin-bottom: 20px;
    }
    .label-list .active {
        border-color: #2370eb;
        color: #2370eb;
    }
    .label-list .personal {
        border-color: #fa8c15;
        color: #fa8c15;
    }
    .but {
        width: 100%;
        height: 48px;
        border-top: 1px solid #e5e5e5;
        display: flex;
        align-items: center;
        background: #fff;
        justify-content: space-between;
        button {
            width: 72px;
        }
    }
}
</style>
