<template>
    <div class="basic">
        <div>
            <p>
                <span class="title">公司名称</span>
                <span class="name_cont">
                    <el-input
                        v-model="data.companyName"
                        size="mini"
                        style="max-width:200px;padding:0 "
                        placeholder="请输入内容"
                        :disabled="disabled"
                    ></el-input>
                    <i
                        class="el-icon-edit-outline"
                        @click="disabled = !disabled"
                        v-show="disabled && isUpData"
                    ></i>
                    <el-button
                        @click="onSubmit(data)"
                        size="mini"
                        type="text"
                        v-show="!disabled"
                        style="color:#2162CB"
                        >保存</el-button
                    >
                </span>
            </p>

            <p>
                <span class="title">最后更新时间</span>
                <span class="cont">{{
                    data.updateTime ? data.updateTime : '- -'
                }}</span>
            </p>
        </div>
        <div>
            <p>
                <span class="title">法定代表人</span>
                <span class="cont">{{
                    data.legalPerson ? data.legalPerson : '- -'
                }}</span>
            </p>
            <p>
                <span class="title">经营状态</span>
                <span class="cont">{{
                    data.companyStatus ? data.companyStatus : '- -'
                }}</span>
            </p>
        </div>
        <div>
            <p>
                <span class="title">统一社会信用代码</span>
                <span class="cont">{{
                    data.creditNo ? data.creditNo : '- -'
                }}</span>
            </p>
            <p>
                <span class="title">成立日期</span>
                <span class="cont">{{
                    data.establishDate ? data.establishDate : '- -'
                }}</span>
            </p>
        </div>
        <div>
            <p>
                <span class="title">曾用名</span>
                <span class="cont">{{
                    data.historyNames ? data.historyNames : '- -'
                }}</span>
            </p>
            <p>
                <span class="title">注册资本</span>
                <span class="cont">{{
                    data.realCapital ? data.realCapital : '- -'
                }}</span>
            </p>
        </div>
        <div>
            <p>
                <span class="title">所在地</span>
                <span class="cont">{{
                    data.companyAddress ? data.companyAddress : '- -'
                }}</span>
            </p>
            <p>
                <span class="title">行业</span>
                <span class="cont">{{
                    data.industry ? data.industry : '- -'
                }}</span>
            </p>
        </div>
        <div>
            <p>
                <span class="title">注册地址</span>
                <span class="cont">{{
                    data.companyAddress ? data.companyAddress : '- -'
                }}</span>
            </p>
        </div>
    </div>
</template>
<script>
import { nameGet } from '@/api/customer/customer.js';
import { editCustomer } from '@/api/user/ae/customer';
export default {
    data() {
        return {
            data: {},
            disabled: true,
            isUpData: false,
        };
    },
    methods: {
        getData(data) {
            // console.log(data);
            if (this.$route.query.type) {
                this.isUpData = true;
            }
            let datas = {
                param: {
                    companyName: data.companyName,
                },
            };
            nameGet(datas).then((res) => {
                // console.log(res);
                this.data = res.data;
            });
        },
        onSubmit(data) {
            let obj = {
                param: {
                    customerDO: {
                        companyName: data.companyName,
                    },
                },
            };

            obj.param.customerDO.id = this.$formDetails.state.data.id;
            editCustomer(obj).then((res) => {
                if (res.code == 200) {
                    this.$message.success('修改成功');
                    this.$emit('againGet');
                    this.disabled = true;
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.basic {
    width: 100%;
    padding-bottom: 10px;
    background: #fff;
    div {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        padding: 7px 24px;
        font-size: 13px;
        p {
            flex: 1;
            display: flex;
            align-items: center;
        }
        span {
            display: inline-block;
        }
        .title {
            min-width: 65px;
            margin-right: 27px;
            color: #666666;
        }
        .cont {
            color: #333333;
            overflow: hidden;
        }
        .name_cont {
            display: flex;
            background: #f9f9f9;
            align-items: center;
            margin-right: 20px;
            cursor: pointer;
            i {
                font-size: 16px;
                color: #2370eb;
            }
        }
    }
}
</style>
