<template>
  <div class="mail_box">
    <div class="content">
      <p>
        <span class="title">预计用户数</span>
        <el-input
          style="padding-right:20px;max-width:200px"
          size="small"
          :disabled="!disabled"
          v-model="data.number"
          placeholder="请输入"
        ></el-input>
      </p>
      <p>
        <span class="title">预计年限</span>
        <el-input
          style="padding-right:20px;max-width:200px"
          size="small"
          :disabled="!disabled"
          v-model="data.term"
          placeholder="请输入"
        ></el-input>
      </p>
    </div>

    <div class="content">
      <p style="align-items:flex-start">
        <span class="title" style="margin-top:5px">联系备注</span>
        <el-input
          :disabled="!disabled"
          style="font-family: PingFangSC-Regular, PingFang SC;font-size:14px"
          type="textarea"
          :rows="2"
          v-model="data.contactRemark"
        >
        </el-input>
      </p>
    </div>
    <div class="content">
      <p style="align-items:flex-start">
        <span class="title" style="margin-top:5px">备注</span>
        <el-input
          :disabled="!disabled"
          style="font-family: PingFangSC-Regular, PingFang SC;font-size:14px"
          type="textarea"
          :rows="2"
          v-model="data.remark"
        >
        </el-input>
      </p>
    </div>
  </div>
</template>
<script>
import { editCustomer } from "@/api/user/ae/customer";
export default {
  data() {
    return {
      data: {},
      disabled: false,
    };
  },
  methods: {
    getData(data) {
      this.data = data;
    },
    upData() {
      this.disabled = true;
    },
    cancel() {
      this.disabled = false;
    },
    onSubmit() {
      let obj = {
        param: {
          customerDO: {
            companyName: this.data.companyName,
            term: this.data.term,
            number: this.data.number,
            contactRemark: this.data.contactRemark,
            remark: this.data.remark,
          },
        },
      };

      obj.param.customerDO.id = this.$formDetails.state.data.id;
      editCustomer(obj).then((res) => {
        if (res.code == 200) {
          this.$message.success("修改成功");
          this.$emit("againGet");
          this.disabled = false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
  /* WebKit browsers 适配谷歌 */
  font-size: 12px !important;
}
.mail_box {
  width: 100%;
  padding-bottom: 10px;
  .content {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 7px 24px;
    font-size: 13px;
    p {
      flex: 1;
      display: flex;
      align-items: center;
    }
    span {
      display: inline-block;
    }
    .title {
      min-width: 65px;
      margin-right: 27px;
      color: #666666;
    }
    .cont {
      color: #333333;
    }
  }
}
</style>
<style>
.mail_box .el-input.is-disabled .el-input__inner {
  background: #fff !important;
}
.mail_box .el-input__inner {
  padding-left: 10px !important;
}
.mail_box .el-input--small .el-input__inner {
  padding-left: 10px !important;
  padding-right: 0 !important;
}
.mail_box .el-textarea.is-disabled .el-textarea__inner {
  background: #fff !important;
  color: #333;
  font-size: 13px;
}
</style>
