<template>
    <div class="basic">
        <div class="add" v-show="isAdd">
            <div>
                <p>
                    <span class="title">域名</span>
                    <span class="name_cont">
                        <el-input
                            v-model="domain"
                            size="mini"
                            style="max-width:200px;padding:0"
                            placeholder="请输入内容"
                        ></el-input>
                        <el-button
                            @click="onAddDomain()"
                            size="mini"
                            type="text"
                            style="color:#2162CB;margin-left:8px"
                            >保存</el-button
                        >
                    </span>
                </p>
            </div>
        </div>
        <div class="main">
            <div>
                <p>
                    <span class="title">域名</span>
                    <span class="name_cont">
                        <el-input
                            v-model="customerData.companyDomain"
                            size="mini"
                            style="max-width:200px;padding:0"
                            placeholder="请输入内容"
                            :disabled="disabled"
                        ></el-input>
                        <i
                            class="el-icon-edit-outline"
                            @click="disabled = !disabled"
                            v-show="disabled && isUpData"
                        ></i>
                        <el-button
                            @click="onUpData(data)"
                            size="mini"
                            type="text"
                            v-show="!disabled"
                            style="color:#2162CB"
                            >保存</el-button
                        >
                    </span>
                </p>
                <p>
                    <span class="title">邮箱品牌</span>
                    <span class="cont">{{
                        $tableDataHandle.primaryEmailBrand(customerData)
                    }}</span>
                </p>
            </div>
            <div>
                <p>
                    <span class="title">负责人</span>
                    <span class="cont">{{
                        data.icpOwner ? data.icpOwner : '- -'
                    }}</span>
                </p>
                <p>
                    <span class="title">备案名称</span>
                    <span class="cont">{{
                        data.icpRecordWebsite ? data.icpRecordWebsite : '- -'
                    }}</span>
                </p>
            </div>
            <div>
                <p>
                    <span class="title">域名注册平台</span>
                    <span class="cont">{{
                        data.whoisRegistrant ? data.whoisRegistrant : '- -'
                    }}</span>
                </p>
                <p>
                    <span class="title">whois</span>
                    <span class="cont"
                        >{{
                            data.icpDomainRecordTime
                                ? data.icpDomainRecordTime
                                : '- -'
                        }}
                        <i class="el-icon-right"></i>
                        {{
                            data.whoisDueDate ? data.whoisDueDate : '- -'
                        }}</span
                    >
                </p>
            </div>
            <div>
                <p>
                    <span class="title">联系人</span>
                    <span class="cont">{{
                        data.whoisContacts ? data.whoisContacts : '- -'
                    }}</span>
                </p>
                <p>
                    <span class="title">联系方式</span>
                    <span class="cont">{{
                        data.whoisContact ? data.whoisContact : '- -'
                    }}</span>
                </p>
            </div>
            <div>
                <p>
                    <span class="title">联系邮箱</span>
                    <span class="cont">{{
                        data.whoisEmail ? data.whoisEmail : '- -'
                    }}</span>
                </p>
                <p>
                    <span class="title">备案号</span>
                    <span class="cont">{{
                        data.icpRecordNo ? data.icpRecordNo : '- -'
                    }}</span>
                </p>
            </div>
            <div>
                <p>
                    <span class="title">域名备案时间</span>
                    <span class="cont">{{
                        data.icpDomainRecordTime
                            ? data.icpDomainRecordTime
                            : '- -'
                    }}</span>
                </p>
                <p>
                    <span class="title">域名服务器</span>
                    <span class="cont">{{
                        data.whoisDomainServer ? data.whoisDomainServer : '- -'
                    }}</span>
                </p>
            </div>
        </div>
        <div
            class="deputy"
            v-for="item in customerData.customerDomainList"
            v-if="item.whois"
        >
            <div>
                <p>
                    <span class="title">域名</span>
                    <span class="name_cont">
                        <el-input
                            v-model="item.domain"
                            size="mini"
                            style="max-width:200px;padding:0"
                            placeholder="请输入内容"
                            :disabled="item.isEdit"
                        ></el-input>
                        <i
                            class="el-icon-edit-outline"
                            @click="onUpDataInput(item)"
                            v-show="item.isEdit"
                        ></i>
                        <el-button
                            @click="onDeputyUpData(item)"
                            size="mini"
                            type="text"
                            v-show="!item.isEdit"
                            style="color:#2162CB"
                            >保存</el-button
                        >
                        <el-button
                            @click="onDelete(item)"
                            size="mini"
                            type="text"
                            v-show="item.isEdit"
                            style="color:#D0021B"
                            >删除</el-button
                        >
                    </span>
                </p>
                <p>
                    <span class="title">邮箱品牌</span>
                    <span class="cont">{{
                        $tableDataHandle.primaryEmailBrand(customerData)
                    }}</span>
                </p>
            </div>
            <div>
                <p>
                    <span class="title">负责人</span>
                    <span class="cont" v-show="item.whois">{{
                        item.whois.icpOwner ? item.whois.icpOwner : '- -'
                    }}</span>
                </p>
                <p>
                    <span class="title">备案名称</span>
                    <span class="cont">{{
                        item.whois.icpRecordWebsite
                            ? item.whois.icpRecordWebsite
                            : '- -'
                    }}</span>
                </p>
            </div>
            <div>
                <p>
                    <span class="title">域名注册平台</span>
                    <span class="cont">{{
                        item.whois.whoisRegistrant
                            ? item.whois.whoisRegistrant
                            : '- -'
                    }}</span>
                </p>
                <p>
                    <span class="title">whois</span>
                    <span class="cont"
                        >{{
                            item.whois.icpDomainRecordTime
                                ? item.whois.icpDomainRecordTime
                                : '- -'
                        }}
                        <i class="el-icon-right"></i>
                        {{
                            item.whois.whoisDueDate
                                ? item.whois.whoisDueDate
                                : '- -'
                        }}</span
                    >
                </p>
            </div>
            <div>
                <p>
                    <span class="title">联系人</span>
                    <span class="cont">{{
                        item.whois.whoisContacts
                            ? item.whois.whoisContacts
                            : '- -'
                    }}</span>
                </p>
                <p>
                    <span class="title">联系方式</span>
                    <span class="cont">{{
                        item.whois.whoisContact
                            ? item.whois.whoisContact
                            : '- -'
                    }}</span>
                </p>
            </div>
            <div>
                <p>
                    <span class="title">联系邮箱</span>
                    <span class="cont">{{
                        item.whois.whoisEmail ? item.whois.whoisEmail : '- -'
                    }}</span>
                </p>
                <p>
                    <span class="title">备案号</span>
                    <span class="cont">{{
                        item.whois.icpRecordNo ? item.whois.icpRecordNo : '- -'
                    }}</span>
                </p>
            </div>
            <div>
                <p>
                    <span class="title">域名备案时间</span>
                    <span class="cont">{{
                        item.whois.icpDomainRecordTime
                            ? item.whois.icpDomainRecordTime
                            : '- -'
                    }}</span>
                </p>
                <p>
                    <span class="title">域名服务器</span>
                    <span class="cont">{{
                        item.whois.whoisDomainServer
                            ? item.whois.whoisDomainServer
                            : '- -'
                    }}</span>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import { domainGet } from '@/api/customer/customer.js';
import { editCustomer } from '@/api/user/ae/customer';
export default {
    data() {
        return {
            data: {},
            disabled: true,
            customerData: {},
            isAdd: false,
            domain: '',
            isUpData: false,
        };
    },
    methods: {
        getData(data) {
            if (this.$route.query.type) {
                this.isUpData = true;
            }
            if (data.companyDomain) {
                let datas = {
                    param: {
                        domain: data.companyDomain,
                    },
                };
                domainGet(datas).then((res) => {
                    this.data = res.data;
                });
            }

            if (data.customerDomainList && data.customerDomainList.length > 0) {
                for (let i = 0; i < data.customerDomainList.length; i++) {
                    if (data.customerDomainList[i].domain) {
                        let obj = {
                            param: {
                                domain: data.customerDomainList[i].domain,
                            },
                        };
                        data.customerDomainList[i].isEdit = true;
                        domainGet(obj).then((res) => {
                            // console.log(res.data);
                            data.customerDomainList[i].whois = res.data;
                        });
                    }
                }
            }
            setTimeout(() => {
                this.customerData = data;
            }, 1000);
        },

        onUpDataInput(item) {
            let a = JSON.stringify(this.customerData);
            let b = JSON.parse(a);
            // console.log(b);
            for (let i in b.customerDomainList) {
                if (item.domain == b.customerDomainList[i].domain) {
                    b.customerDomainList[i].isEdit = false;
                }
            }
            this.customerData = b;
            // console.log(this.customerData);
        },
        onUpData() {
            let obj = {
                param: {
                    customerDO: {
                        companyName: this.customerData.companyName,
                        companyDomain: this.customerData.companyDomain,
                    },
                },
            };
            obj.param.customerDO.id = this.$formDetails.state.data.id;
            editCustomer(obj).then((res) => {
                if (res.code == 200) {
                    this.$message.success('修改成功');
                    this.$emit('againGet');
                    this.disabled = true;
                }
            });
        },
        onDeputyUpData(item) {
            let obj = {
                param: {
                    customerDO: {
                        companyName: this.customerData.companyName,
                    },
                    customerDomainList: this.customerData.customerDomainList,
                },
            };
            obj.param.customerDO.id = this.$formDetails.state.data.id;
            editCustomer(obj).then((res) => {
                if (res.code == 200) {
                    this.$message.success('修改成功');
                    this.$emit('againGet');
                    this.disabled = true;
                }
            });
        },
        // 添加域名
        onAdd() {
            this.isAdd = true;
        },
        onAddDomain() {
            let obj = {
                param: {
                    customerDO: {
                        companyName: this.customerData.companyName,
                    },
                    customerDomainList: this.customerData.customerDomainList,
                },
            };
            obj.param.customerDO.id = this.$formDetails.state.data.id;
            obj.param.customerDomainList.push({ domain: this.domain });
            editCustomer(obj).then((res) => {
                if (res.code == 200) {
                    this.$message.success('添加成功');
                    this.$emit('againGet');
                    this.isAdd = false;
                    this.domain = '';
                }
            });
        },
        // 删除域名
        onDelete(item) {
            let obj = {
                param: {
                    customerDO: {
                        companyName: this.customerData.companyName,
                    },
                    customerDomainList: this.customerData.customerDomainList,
                },
            };
            obj.param.customerDO.id = this.$formDetails.state.data.id;
            for (let i in this.customerData.customerDomainList) {
                if (this.customerData.customerDomainList[i].id == item.id) {
                    this.customerData.customerDomainList.splice(i, 1);
                }
            }
            editCustomer(obj).then((res) => {
                if (res.code == 200) {
                    this.$message.success('删除成功');
                    this.$emit('againGet');
                    this.isAdd = false;
                    this.domain = '';
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.basic {
    width: 100%;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    .main {
        padding-bottom: 10px;
    }
    .add,
    .main,
    .deputy {
        div {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
            padding: 7px 24px;
            font-size: 13px;
            p {
                flex: 1;
                display: flex;
                align-items: center;
            }
            span {
                display: inline-block;
            }
            .title {
                min-width: 65px;
                margin-right: 27px;
                color: #666666;
            }
            .cont {
                color: #333333;
            }
            .name_cont {
                display: flex;
                background: #f9f9f9;
                align-items: center;
                margin-right: 20px;
                cursor: pointer;
                i {
                    font-size: 16px;
                    color: #2370eb;
                }
            }
        }
    }
    .deputy {
        border-top: 1px dashed #eeeeee;
        padding: 10px 0;
    }
    .add {
        border-bottom: 1px dashed #eeeeee;
        padding: 10px 0;
        .name_cont {
            display: flex;
            align-items: center;
            margin-right: 20px;
            background: #fff !important;
            cursor: pointer;
        }
    }
}
</style>
