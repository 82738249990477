<template>
    <div class="details">
        <div class="title_box">
            <div class="name_operation">
                <p>
                    <span>
                        <i class="iconfont icon-wodekehu"></i>
                        {{ data.companyName }}
                    </span>
                </p>
                <p>
                    <el-button
                        @click="onClose()"
                        size="mini"
                        type="primary"
                        icon="el-icon-arrow-left"
                        style="background: #fff; color: #2370eb; border-color: #2370eb"
                        >返回列表</el-button
                    >
                </p>
            </div>
            <div class="relation_content">
                <span
                    >关联客户：
                    <span class="text">{{ data.customerName }}</span>
                </span>
                <span
                    >员 工： <span class="text">{{ data.saleAdminName }}</span></span
                >

                <span
                    >域 名： <span class="text">{{ data.domain }}</span></span
                >
            </div>
        </div>
        <div class="box_tab">
            <div class="top_box">
                <div class="tab-box">
                    <p @click="onSwitch(1)" :class="index == 1 ? 'isColor' : ''">
                        基础信息
                        <span></span>
                    </p>
                    <p @click="onSwitch(2)" :class="index == 2 ? 'isColor' : ''">
                        交易数据<span></span>
                    </p>
                </div>
                <div>
                    <button class="add" v-if="!data.customerId" @click="onRelation()">
                        <i class="iconfont icon-guanlian"></i> 关联客户
                    </button>
                </div>
            </div>
            <div class="information" v-show="index == 1 && data.customerId != 0">
                <Information ref="information" @againGet="getData" />
            </div>
            <div class="notYet" v-show="index == 1 && data.customerId == 0">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
            <div class="trade" v-show="index == 2">
                <Trade ref="trade" @againGet="getData" />
            </div>
        </div>
        <!-- 关联客户 -->
        <el-dialog
            :visible.sync="dialogRelation"
            width="560px"
            :before-close="handleRelation"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    关联客户
                </div>
            </template>
            <div>
                <RelationCus @close="handleRelation" ref="relationCus" />
            </div>
        </el-dialog>
    </div>
</template>
<script>
import Information from './information/information.vue';
import Trade from './trade/trade.vue';
import RelationCus from './relationCus/relationCus.vue';
import { closedCusGet } from '@/api/closedCus/closedCus';
import { getCustomerId } from '@/api/user/ae/customer';
export default {
    components: {
        Information,
        Trade,
        RelationCus,
    },
    data() {
        return {
            data: {},
            isUpData: false,
            index: 1,
            dialogRelation: false,
        };
    },
    mounted() {
        if (this.$route.query.type) {
            this.isUpData = true;
        }
        this.getData();
    },

    methods: {
        getData() {
            let data = {
                param: {
                    id: this.$route.query.id,
                },
            };
            closedCusGet(data).then((res) => {
                this.data = res.data;
                // console.log(this.data);
                let that = this;
                let data = {
                    param: {
                        id: res.data.customerId,
                    },
                };
                getCustomerId(data).then((res) => {
                    this.$formDetails.commit('detailsData', JSON.stringify(res.data));
                    this.$choiceLabel.commit('getType', '2');
                    this.$refs.information.getData(res.data);
                    this.$refs.trade.getData(that.data);
                });
            });
        },
        onSwitch(i) {
            this.index = i;
            if (this.index == 1) {
                let data = {
                    param: {
                        id: this.$formDetails.state.data.id,
                    },
                };
                getCustomerId(data).then((res) => {
                    // console.log(res);
                    this.$refs.information.getData(res.data);
                });
            } else if (this.index == 2) {
                this.$refs.trade.getData(this.data);
            }
        },
        onRelation() {
            this.dialogRelation = true;
            setTimeout(() => {
                this.$refs.relationCus.getRelation(this.data);
            });
        },
        handleRelation() {
            this.dialogRelation = false;
            this.getData();
        },
        onClose() {
            window.history.go(-1);
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.details {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .title_box {
        padding: 16px 20px;
        background: #fff;
        .name_operation {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            p {
                i {
                    color: #2370eb;
                    margin-right: 12px;
                }
                span {
                    font-size: 16px;
                }
            }
        }
        .relation_content {
            font-size: 14px;
            color: #666;
            padding: 12px 0 14px 0;
            .text {
                color: #333333;
                margin-right: 32px;
            }
        }
    }
    .box_tab {
        flex: 1;
        display: flex;
        flex-direction: column;
        min-height: 0;
        margin-top: 8px;

        .top_box {
            background: #fff;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #eeeeee;
            button {
                font-size: 12px;
                padding: 0 7px 0 12px;
                height: 22px;
                background: #ffffff;
                border-radius: 2px;
                border: 1px solid #2370eb;
                cursor: pointer;
                color: #2370eb;
                margin-left: 16px;
            }

            button:hover {
                background: #2370eb;
                color: #ffffff;
                border-color: #2370eb;
            }
        }

        .tab-box {
            display: flex;
            align-items: center;
            height: 44px;
            p {
                cursor: pointer;
                font-weight: 500;
                color: #666666;
                font-size: 14px;
                padding: 0 16px;
                height: 100%;
                line-height: 44px;
                width: auto;
            }
            .isColor {
                color: #2370eb;
                position: relative;
                span {
                    display: block;
                    width: 16px;
                    height: 3px;
                    background: #2370eb;
                    border-radius: 1px 1px 0px 0px;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    margin-left: -8px;
                }
            }
        }
    }
    .information {
        flex: 1;
        min-height: 0;
    }
    .trade {
        flex: 1;
        min-height: 0;
    }
}
.add {
    border-color: #d9d9d9;
    color: #333333;
    margin-right: 12px;
}
.notYet {
    flex: 1;
    display: flex;
    background: #fff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
        width: 100px;
        margin-bottom: 20px;
    }
}
</style>
<style>
.details .box_tab .tab-box[data-v-95ef676c] {
    border-bottom: 0 !important;
}
</style>
