<template>
    <div>
        <el-table
            class="table-box"
            ref="multipleTable"
            :data="tableData"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column width="10"> </el-table-column>

            <el-table-column
                label="企业名称"
                min-width="150"
                prop="companyName"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column label="CorpID" prop="corpId" min-width="150" show-overflow-tooltip>
            </el-table-column>
            <el-table-column label="管理员账号" prop="adminAccount" show-overflow-tooltip>
            </el-table-column>
            <el-table-column label="密保邮箱" prop="" show-overflow-tooltip> </el-table-column>
            <el-table-column label="开通时间-到期时间" show-overflow-tooltip min-width="130">
                <template slot-scope="scope">
                    <span>
                        <span class="start">始</span>
                        {{ scope.row.reportTime ? scope.row.reportTime.slice(0, 10) : '- -' }}</span
                    ><br />
                    <span>
                        <span class="end">末</span>
                        {{
                            scope.row.reportExpireTime
                                ? scope.row.reportExpireTime.slice(0, 10)
                                : '- -'
                        }}</span
                    >
                </template>
            </el-table-column>
            <el-table-column label="系统回收时间" show-overflow-tooltip min-width="100">
                <template slot-scope="scope">
                    <span>
                        {{
                            scope.row.recoveryTime ? scope.row.recoveryTime.slice(0, 10) : ''
                        }}</span
                    >
                </template>
            </el-table-column>
            <el-table-column prop="domain" label="域名" show-overflow-tooltip min-width="100">
            </el-table-column>
            <el-table-column label="下单后台" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{
                        scope.row.placeBackstage == 0
                            ? '伽搜boss'
                            : scope.row.placeBackstage == 1
                            ? '钱邮'
                            : scope.row.placeBackstage == 2
                            ? '腾讯云'
                            : scope.row.placeBackstage == 3
                            ? '阿里云'
                            : scope.row.placeBackstage == 4
                            ? '925'
                            : scope.row.placeBackstage == 5
                            ? '华为云'
                            : scope.row.placeBackstage == 6
                            ? '云梦'
                            : scope.row.placeBackstage == 7
                            ? '华中'
                            : scope.row.placeBackstage == 8
                            ? '山总'
                            : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column label="账号数" show-overflow-tooltip prop="number"> </el-table-column>
            <el-table-column label="启用/创建账户" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span style="color: #2370EB;">{{ scope.row.enabledNumber }}</span>
                    /
                    <span>{{ scope.row.createNumber }}</span>
                </template></el-table-column
            >
            <el-table-column label="VIP账号" show-overflow-tooltip prop="vipNumber">
            </el-table-column>
            <el-table-column label="API配置" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-button
                        type="text"
                        v-show="scope.row.corpId"
                        style="color:#2370EB"
                        @click="onConfigure(scope.row)"
                        >配置</el-button
                    >
                </template>
            </el-table-column>

            <div slot="empty" class="empty" style="margin-top:20px">
                <img src="@/assets/zanwushuju.png" />
                <span style="display:block">暂无数据～</span>
            </div>
        </el-table>
        <!-- 配置 -->
        <el-dialog :visible.sync="dialog" width="560px" :before-close="handleClose" append-to-body>
            <template slot="title">
                <div
                    style="display:flex; align-items: center;color:#000000;font-size:16px;font-weight: 500;"
                >
                    配置
                </div>
            </template>
            <div>
                <el-form
                    style="flex:1;margin-top:20px;margin-left:40px;margin-bottom:80px"
                    label-position="left"
                    label-width="50px"
                >
                    <el-form-item label="密钥">
                        <i
                            class="el-icon-warning"
                            v-show="
                                !verifyData.verifySecretStatus || verifyData.verifySecretStatus == 2
                            "
                        ></i>
                        <i class="el-icon-success" v-show="verifyData.verifySecretStatus == 1"></i>
                        <el-input
                            v-model="corpSecret"
                            size="small"
                            style="width:300px"
                            placeholder="请输入密钥"
                            clearable=""
                        ></el-input>
                        <el-button
                            @click="onSecret"
                            size="small"
                            type="primary"
                            style="margin-left:10px;background:#fff;color:#2370EB;border-color:#2370EB"
                            >验证</el-button
                        >
                    </el-form-item>
                    <p>
                        最后验证时间

                        <span> {{ verifyData.verifySecretTime }}</span>
                    </p>
                </el-form>
                <div class="but">
                    <el-button
                        round
                        type="primary"
                        size="mini"
                        style="background:none;border-color:#D5D6DF;color:#666666;margin-left: auto;"
                        @click="handleClose"
                        >取消</el-button
                    >
                    <el-button
                        round
                        type="primary"
                        style="background:#2370EB;border-color:#2370EB;color:#fff;margin-right:30px"
                        size="mini"
                        @click="handleClose"
                        >确定</el-button
                    >
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { verifySecret } from '@/api/closedCus/closedCus';
export default {
    data() {
        return {
            tableData: [],
            dialog: false,
            isVerify: false,
            data: {},
            corpSecret: '',
            verifyData: {},
        };
    },
    methods: {
        getData(data) {
            // console.log(data);
            this.tableData = data;
        },
        onConfigure(row) {
            this.dialog = true;
            this.data = row;
            this.verifyData.verifySecretTime = row.verifySecretTime;
            this.corpSecret = row.corpSecret;
        },
        handleClose() {
            this.dialog = false;
        },
        onSecret() {
            if (!this.corpSecret) {
                return this.$message.error('请输入密钥');
            }
            let data = {
                param: {
                    id: this.data.id,
                    corpId: this.data.corpId,
                    corpSecret: this.corpSecret,
                },
            };
            verifySecret(data).then((res) => {
                if (res.code == 200) {
                    this.verifyData = res.data;
                    this.isVerify = true;
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.start {
    font-size: 8px;
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    text-align: center;
    line-height: 20px;
    background: rgba(35, 112, 235, 0.15);
    color: #2370eb;
}
.end {
    font-size: 8px;
    display: inline-block;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    border-radius: 50%;
    background: rgba(250, 100, 0, 0.11);
    color: #fa6400;
}
.but {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    background: #fff;
    justify-content: space-between;
    button {
        width: 72px;
    }
}
.el-icon-warning {
    position: absolute;
    left: -70px;
    top: 13px;
    color: #d0021b;
}
.el-icon-success {
    position: absolute;
    left: -70px;
    top: 13px;
    color: #09d002;
}
</style>
